import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import AdminDashboard from './dashboard/AdminDashboard';
import UsersDashboard from './users/UsersDashboard';

const AdminRouter = () => {
  return (
    <Switch>
      <Route path="/users">
        <UsersDashboard />
      </Route>
      <Route path="/">
        <AdminDashboard />
      </Route>
      <Route path="*">
        <AdminDashboard />
      </Route>
    </Switch>
  );
};

export default AdminRouter