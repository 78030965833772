import  React, { useState } from 'react';
import { Button } from '@mui/material';

const Confirmation = ({
  close
}) => {
  const qrCodeImageUrl = "/images/qr.png";
  const checkmarkImageUrl = "/images/check_circle1.png";


  return (
    <div className="">
      <div className="flex items-center justify-center mb-[12px]">
        <img src={checkmarkImageUrl} className="w-[50px]"/>
      </div>
      <div className="flex items-center justify-center">
        <img className="w-[250px] my-[12px]" src={qrCodeImageUrl} />
      </div>
      <div className="flex items-center justify-center text-center">
        <div class="text-center text-black text-lg font-light">
          Congratulations!
          <br/>
          You unlocked a code.
        </div>
      </div>
      <div className="mt-[20px] flex items-center justify-center text-center">
        <Button onClick={close} color="primary" variant="contained" fullWidth>Close</Button>
      </div>
    </div>
  )
}

export default Confirmation