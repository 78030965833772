import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import CommentsTable from '../comments/CommentsTable';
const AdminDashboard = () => {
  return (

      <Card sx={{
        m: 2
      }}>
        <CardContent>
          <Typography variant="h5" component="div">
            Comments
          </Typography>
          <CommentsTable />
        </CardContent>
      </Card>
  )
}

export default AdminDashboard