import React, { useState, useRef, useEffect } from 'react';
import RoadMapMessage from './RoadMapMessage';
import MakeMessageModal from '../form/MakeMessageModal';

const RoadMap = ({
  onClick,
  messages,
  enableMessageMarker,
  messageMarkerPosition
}) => {
  const ref = useRef(null)
  const aspectRatio = 16 / 9;
  const roadMapImageUrl = "/images/bg4.jpg";
  const [ width, setWidth ] = useState(0);

  useEffect (() => {
    setWidth( ref.current ? ref.current.offsetWidth : 0)
  },[ref.current, window.innerWidth])

  const onRoadmapClick = (e) => {
    var rect = ref.current.getBoundingClientRect();
    var x = (e.clientX - rect.left)/rect.width; //x position within the element.
    var y = (e.clientY - rect.top)/rect.height;  //y position within the element.

    onClick({x, y})
  }

  const getScale = () => {
    return width / 1920;
  }

  const renderMessages = () => {
    return (messages||[]).sort((a, b) => a.id - b.id).map((message, index) => {
      return (
        <RoadMapMessage
          key={`roadmap-message-${message.id}`}
          message={message}
          scale={getScale()}
        />
      );
    });
  }

  renderMessageMarker = () => {
    if(enableMessageMarker){
      return (
        <div 
          className={`road-map-message-marker-container`}
          style={{
            transform: `translate(-50%, -50%) scale(${getScale()}) `,
            left: `${messageMarkerPosition.x*100}%`,
            top: `${messageMarkerPosition.y*100}%`,
          }}
        >
          <div
            className={`road-map-message-marker`}
          />
        </div>
      )
    }
  }


  return (
    <>
      <div className="roadmap-container" ref={ ref } onClick={onRoadmapClick}>
        {renderMessageMarker()}
        {renderMessages()}
        <div className="roadmap"style={{
          backgroundImage: `url(${roadMapImageUrl})`,
          paddingBottom: `${ (1/aspectRatio)*100}%`
        }}/>
      </div>
    </>
  )
  
};

export default RoadMap