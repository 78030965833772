import React, { useState, useEffect } from 'react';
import DataGridTableToolbar from "../DataGridTableToolbar";
import {
  Button
} from "@mui/material";
import {
  DataGridPremium
} from "@mui/x-data-grid-premium";
import {useConfirm} from 'material-ui-confirm';

const CommentsTable = (props) => {
  const [ comments, setComments ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const confirm = useConfirm();

  const fetchComments = async () => {
    const response = await fetch('/admin/api/comments').then(x=> x.json()).then(json => {
      setComments(json.comments)
      setLoading(false);
    }).catch((error)=>{
      console.log(error);
      alert("An Error Occurred Loading Comments");
    })
  }

  useEffect(() => {
    fetchComments();
  }, []);

  const columns = [
    { field: 'name_first', headerName: 'First Name', editable: true, flex: 1 },
    { field: 'name_last', headerName: 'Last Name', editable: true, flex: 1 },
    { field: 'body', headerName: 'Comment', editable: true, flex: 3 },
    { field: 'x_position', headerName: 'X Position', type: 'number', editable: true, flex: 1 },
    { field: 'y_position', headerName: 'Y Position', type: 'number', editable: true, flex: 1 },
    { field: 'created_at', sort: true, headerName: 'Created At', type: 'dateTime', editable: true, flex: 1, valueGetter: (params) => new Date(params.value) },
    { field: "actions", flex: 1, headerName: "Actions", renderCell: (params) => (
      <>
        <Button onClick={() => attemptDeleteComment(params.row.id)}>Delete</Button>
      </>
    )}
  ];

  const attemptDeleteComment = (id) => {
    confirm({
      description: "Are you sure you want to delete this comment? This action cannot be undone",
      confirmationText: "Yes, delete",
      cancellationText: "No, keep",
    }).then(() => {
      fetch(`/admin/api/comments/${id}`, {
        method: "DELETE",
      }).then(fetchComments);
    })
  }

  const saveComments = (
    newRow,
    oldRow
  ) => {

    fetch(`/admin/api/comments/${oldRow.id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        comment: {
          body: newRow.body,
          name_first: newRow.name_first,
          name_last: newRow.name_last,
          x_position: newRow.x_position,
          y_position: newRow.y_position
        }
      }),
    })
    return newRow
  }

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }
  return (
    <>
    <Button
      onClick={() => {setLoading(true); fetchComments()}}
    > Refresh </Button>
    <DataGridPremium
      //onCellEditCommit={saveComments}
      processRowUpdate={saveComments}
      onProcessRowUpdateError={(error) => console.log(error)}
      autoHeight
      unstable_headerFilters
      rows={comments}
      columns={columns}
      slots={{
        toolbar: DataGridTableToolbar
      }}
      style={{
        cell: {
          paddingBottom: 0,
          paddingRight: 0,
          whiteSpace: 'normal',
          overflow: 'visible',
        },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: 'created_at', sort: 'desc' }],
        },
        pagination: {
          paginationModel: { page: 0, pageSize: 5 },
        },
        columns: {
          columnVisibilityModel: {
            hidden: false,
          },
        },
        filter: {
          filterModel: {
            items: [
              {
                field: 'hidden',
                operator: 'is',
                value: "false",
              }
            ]
          }
        }
      }}
      pageSizeOptions={[5, 10, 20, 50]}
    />
    </>
  )
}

export default CommentsTable