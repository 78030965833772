import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-mui';
import { 
  Button 
}  from '@mui/material';

const MessageForm = ({
  messagePositionX,
  messagePositionY,
  cancel,
  next,
  values
}) => {

  const renderForm = (formikProps) => {
    return (
      <Form>
        <div className="flex mt-4">
          <div className="w-1/2 mr-4">
            <Field
              component={TextField}
              name="name_first"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              sx={{
                background: "white"
              }}
            />
          </div>
          <div className="w-1/2">
            <Field
              component={TextField}
              name="name_last"
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              sx={{
                background: "white"
              }}
            />
          </div>
        </div>
        <div className="flex mt-4">
          <div className="w-full">
            <Field
              component={TextField}
              name="body"
              label="Comment"
              variant="outlined"
              placeholder={"Add your comment"}
              fullWidth
              multiline
              rows={4}
              required
              sx={{
                background: "white"
              }}
            />
          </div>
        </div>
        {renderButtons(formikProps)}
      </Form>
    )
  }

  const renderButtons = (formikProps) => {
    return (
      <div className="flex mt-4">
        <div className="">
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={cancel}
          >
            Cancel
          </Button>
        </div>
        <div className="grow">

        </div>
        <div className="">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
          >
            Next
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        name_first: values.name_first || "",
        name_last: values.name_last || "",
        body: values.body || "",
        x_position: messagePositionX,
        y_position: messagePositionY
      }}
      onSubmit={next}
    >
      {(formikProps) => (
        renderForm(formikProps)
      )}
    </Formik>
  );
}

export default MessageForm