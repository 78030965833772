import React, {
  useState,
  useEffect
} from "react";
import theme from "./DigitalMuiTheme";
import { ThemeProvider } from "@mui/material";
import RoadMap from "./roadmap/RoadMap";
import { fetchMessages, getRenderableMessages } from "./MessageUtils";
import useInterval from "../useInterval";

const DigitalSignageIndex = () => {
  const [ allMessages, setAllMessages ] = useState([])
  const [ messages, setMessages ] = useState([])
  const pollTime = 1500;
  const [ count, setCount ] = useState(0);
  useInterval(
    () => {
      getMessages(count);
      setCount(count + 1);
    },
    pollTime
  )

  const getMessages = async () => {
    if(count % 5 == 0) {
    const fetchedMessage = await fetchMessages();
    if(fetchedMessage) {
      setMessages(oldMessages => {
        return getRenderableMessages(fetchedMessage, oldMessages)
      })
      setAllMessages(fetchedMessage)
    }
  }else {
    setMessages(oldMessages => {
      return getRenderableMessages(allMessages, oldMessages)
    })
  }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="bg-black h-[100vh] flex items-center justify-center">
      <div className="
        w-[100vw]
 
      ">
        <RoadMap
          disableMessageCreation={true}
          messages={messages}
        />
      </div>
    </div>
    </ThemeProvider>
  );
};

export default DigitalSignageIndex