
const fetchMessages = async () => {
  const response = await fetch('/api/v1/comments',  {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const json = await response.json();
  if(json.error) {
    console.log({
      error: json.error
    })
    return null
  }else {
    return json.comments
  }
}

const getRenderableMessages = (messages, oldRenderedMessages) => {
  let renderedMessages = []
  const recentMessagesToRenderCount = 10; //Always render these
  const totalMessagesToRenderCount = 70;
  const messagesToChangeEveryTick = 3;
  const sortedMessages = messages.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
  renderedMessages = sortedMessages.slice(0, recentMessagesToRenderCount);

  if(!oldRenderedMessages || oldRenderedMessages.length  == 0) {
    const oldMessagesToAdd = messages.filter(x=> renderedMessages.map(y => y.id).indexOf(x.id) == -1).sort(() => Math.random() - 0.5).slice(0, totalMessagesToRenderCount - recentMessagesToRenderCount);
    renderedMessages = [...renderedMessages, ...oldMessagesToAdd];
  }
  else if(sortedMessages.length > recentMessagesToRenderCount) {
    const oldOldMessages = oldRenderedMessages.filter(x=>!x.fadeOut).filter(x=> renderedMessages.map(y => y.id).indexOf(x.id) == -1)
    renderedMessages = [...oldOldMessages, ...renderedMessages];
    //Add N new messages not existing to renderedMessages 
    const allAddableMessages = messages.filter(x=> renderedMessages.map(y => y.id).indexOf(x.id) == -1)
    const randomMessagesToAdd = allAddableMessages.sort(() => Math.random() - 0.5).slice(0, messagesToChangeEveryTick);
    renderedMessages = [...renderedMessages, ...randomMessagesToAdd]

    if(renderedMessages.length > totalMessagesToRenderCount) {
      const randomMessagesToRemove = oldOldMessages.sort(() => Math.random() - 0.5).slice(0, messagesToChangeEveryTick);
      oldOldMessages.map(x=> {
        if(randomMessagesToRemove.map(y => y.id).indexOf(x.id) != -1) {
          x.fadeOut = true
        }
      })
    }
  }

  return renderedMessages
}


export {
  fetchMessages,
  getRenderableMessages
}