import React, { useEffect, useState } from "react";
import RoadMap from "./roadmap/RoadMap";
import MakeMessageModal from "./form/MakeMessageModal";
import theme from "./DigitalMuiTheme";
import { ThemeProvider } from "@mui/material";
import {
  fetchMessages,
  getRenderableMessages
} from "./MessageUtils";
import { create } from "lodash";

import useInterval from "../useInterval";
const TouchScreenIndex = () => {
  const footerImageUrl = "/images/discover-logo.png";
  const [ allMessages, setAllMessages ] = useState([])
  const [ messages, setMessages ] = useState([])
  const [ makeMessageModalOpen, setMakeMessageModalOpen ] = useState(false)
  const [ createdMessage, setCreatedMessage ] = useState(false)
  const [ messagePosition, setMessagePosition ] = useState({
    x: 0.5,
    y: 0.5
  })

  const pollTime = 1500;
  const [ count, setCount ] = useState(0);
  useInterval(
    () => {
      getMessages(count);
      setCount(count + 1);
    },
    pollTime
  )
  // useEffect(() => {
  //   getMessages(true);
  //   window.setInterval(
  //     getMessages,
  //     pollTime
  //   )
  //   return () => {
  //     window.clearInterval(timer);
  //   };
  // },[])


  const getMessages = async (count) => {
    console.log({
      count
    })
    if(count % 5 == 0) {
      const fetchedMessage = await fetchMessages();
      if(fetchedMessage) {
        setMessages(oldMessages => {
          return getRenderableMessages(fetchedMessage, oldMessages)
        })
        setAllMessages(fetchedMessage)
      }
    }else {
      setMessages(oldMessages => {
        return getRenderableMessages(allMessages, oldMessages)
      })
    }
  }

  const renderMakeMessageModal = () => {
    return (
      <MakeMessageModal
        open={  makeMessageModalOpen }
        closeModal={() => setMakeMessageModalOpen(false)}
        callbackSuccess={(message) => {
          setCreatedMessage(true)
          setMessages([...messages, message])
        }}
        messagePositionX={messagePosition.x}
        messagePositionY={messagePosition.y}
      />
    );
  }

  onMapClick = (position) => {
    if(makeMessageModalOpen) return;
    setMessagePosition(position)
    setCreatedMessage(false)
    setMakeMessageModalOpen(true)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="
        w-full h-full
        px-[35px]
      ">
        {renderMakeMessageModal()}
        <div onClick={getMessages} class="text-center text-[#130032] text-3xl font-light p-[35px]">
          Click anywhere on the Product Roadmap to add your comment
        </div>
        <div className="m-auto max-w-[1200px]">
          <RoadMap 
            messages={messages}
            onClick={
              onMapClick
            }
            enableMessageMarker={makeMessageModalOpen && !createdMessage}
            messageMarkerPosition={messagePosition}
          />
        </div>
        <div
          className="w-full p-[40px]"
        >
          <img src={footerImageUrl} className="w-[300px] m-auto"/>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default TouchScreenIndex