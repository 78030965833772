import { values } from 'lodash';
import React, { useState } from 'react';
import { Button } from '@mui/material';
const PreviewMessage = ({
  values,
  edit,
  post
}) => {


  const renderButtons = (formikProps) => {
    return (
      <div className="flex mt-4">
        <div className="">
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={edit}
          >
            Edit
          </Button>
        </div>
        <div className="grow">

        </div>
        <div className="">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            onClick={post}
          >
            Post
          </Button>
        </div>
      </div>
    );
  }


  getFirstLetterOfString = (str) => {
    if (str && str.trim().length > 0) {
      return (str||"").trim()[0];
    } else {
      return "";
    }
  }


  return (
    <div className="">
      <div className="flex items-center">
        <div className="w-[34px] h-[34px] bg-[#f9555e] rounded-full flex items-center justify-center text-white mr-[12px]">
          {`${getFirstLetterOfString(values.name_first)}${getFirstLetterOfString(values.name_last)}`.toUpperCase()}
        </div>
        <div class="text-[#010101] text-lg font-medium">{values.name_first} {values.name_last}</div>
      </div>
      <div class="py-[24px] text-black text-lg font-light">
        {values.body}
      </div>
      {renderButtons()}
    </div>
  );
}

export default PreviewMessage