import React from "react";

const RoadMapMessage = ({
  message,
  scale
}) => {

  getFirstLetterOfString = (str) => {
    if (str && str.trim().length > 0) {
      return (str||"").trim()[0];
    } else {
      return "";
    }
  }


  return (
    <div
      className={`roadmap-message-container ${message.fadeOut ? "roadmap-message-container-fade-out" : "roadmap-message-container-fade-in"}`}
      style={{
        transform: `translate(-50%, -50%) scale(${scale}) `,
        left: `${message.x_position*100}%`,
        top: `${message.y_position*100}%`,
      }}
    >
      <div className="roadmap-message">
        <div className="roadmap-message-inner">
          <div className="name">
            {`${getFirstLetterOfString(message.name_first)}${getFirstLetterOfString(message.name_last)}`.toUpperCase()}
          </div>
          <div className="body">
            <b>
              {`${message.name_first} ${message.name_last}`}
              <br/>
            </b>
            {message.body}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMapMessage