import React,  { useState } from 'react';
import Modal from "react-modal";
import MessageForm from './MessageForm';
import PreviewMessage from './PreviewMessage';
import Confirmation from './Confirmation';
import Cookies from 'universal-cookie';
const MakeMessageModal = ({
  open,
  closeModal,
  messagePositionX,
  messagePositionY,
  callbackSuccess
}) => {
  const [page, setPage] = useState(0);
  const [ values, setValues] = useState({})
  const resetModal = () => {
    setPage(0);
    closeModal();
    setValues({})
  }
  
  const submitForm = (values) => {
    setValues(values)
    setPage(1)
  }

  const saveComment = () => {
    const token = new Cookies().get("X-CSRF-Token")
    fetch("/api/v1/comments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({comment: values})
    }).then(x=> x.json()).then(x => {
      if(!x.error) {
        callbackSuccess(x.comment)
      }
    })
    callbackSuccess(values)
    setPage(2);
  }

  const renderPage = () => {
    switch(page) {
      case 0:
        return (
          <MessageForm 
            messagePositionX={messagePositionX}
            messagePositionY={messagePositionY}
            cancel={resetModal}
            next={submitForm}
            values={values}
          />
        )

      case 1:
        return (
          <PreviewMessage
            values={values}
            edit={()=>{
              setPage(0)
            }}
            post={saveComment}
          />
        )
      case 2: 
        return (
          <Confirmation
            close={resetModal}
          />
        )
      default:
        resetModal()
    }
  }



  return (
    <Modal
      isOpen={open}
      className="roadmap-modal"
      overlayClassName="react-modal-overlay"
      contentLabel="Message Form"
      ariaHideApp={false}
      onRequestClose={()=> {
        if(page == 2) {
          resetModal()
        }
      }}
      style={{
        overlay: {
          zIndex: 1202
        },
        content: {
          width: page == 0 ? "600px" : "350px",
          margin: "auto",
        }
      }}
    >
      {renderPage()}
    </Modal>
  );
};

export default MakeMessageModal